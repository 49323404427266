<template>
  <div>

    <BaseTableHeader
      @searchChange="searchChange"
      @refresh="refresh"
    />

    <BaseTable
      :headers="headers"
      :items="alerts"
      :search="search"
      rowKey="rowId"
      @rowClick="rowClick"
    />

  </div>
</template>

<script>
import { headers } from '@/components/config/userAlerts';
import baseTableMixin from '@/components/mixins/workspace/baseTableMixin';

export default {
  name: 'UserAlertTable',
  mixins: [baseTableMixin],
  props: {
    alerts: Array,
  },
  data: () => ({
    headers,
  }),
  mounted() {
    const iconHeader = headers.find(header => header.value === 'icon');

    if (iconHeader) {
      iconHeader.format = (item, index, createElement) => (
        createElement('v-icon', {
          props: {
            color: item.iconColor,
          },
        }, item[index])
      );
    }
  },
};
</script>
